
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'120px'"
      >
        <el-form-item label="企业名称" prop="companyId">
          <el-select-tree
            :props="companyProps"
            :options="companyResult"
            v-model="formModel.companyId"
            style="width: 300px"
            size="mediumn"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="班次名称" prop="name">
          <el-input
            v-model="formModel.name"
            placeholder="请输入班次名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="周工作日" prop="weekdays">
          <el-select
            v-model="formModel.weekdays"
            filterable
            multiple
            placeholder="请选择"
            style="width:100% "
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上班时间" prop="startTime">
          <el-time-picker
            v-model="formModel.startTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="上班提前" prop="startAdvanceMinutes">
          <el-input
            v-model="formModel.startAdvanceMinutes"
            placeholder="请输入上班提前分钟"
            style="width: 200px"
          >
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
        <el-form-item label="上班延迟" prop="startDelayMinutes">
          <el-input
            v-model="formModel.startDelayMinutes"
            placeholder="请输入上班延迟分钟"
            style="width: 200px"
          >
            <template slot="append">分钟算迟到</template>
          </el-input>
        </el-form-item>
        <el-form-item label="下班时间" prop="endTime">
          <el-time-picker
            v-model="formModel.endTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="下班提前" prop="endAdvanceMinutes">
          <el-input
            v-model="formModel.endAdvanceMinutes"
            placeholder="请输入下班提前分钟"
            style="width: 200px"
          >
            <template slot="append">分钟算早退</template>
          </el-input>
        </el-form-item>
        <el-form-item label="下班延迟" prop="endDelayMinutes">
          <el-input
            v-model="formModel.endDelayMinutes"
            placeholder="请输入下班延迟分钟"
            style="width: 200px"
          >
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import workScheduleApi from "@/api/base/workSchedule";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        name: [
          { required: true, message: "班次名称不能为空", trigger: "blur" },
        ],
        weekdays: [
          { required: true, message: "周工作日不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "上班时间不能为空", trigger: "blur" },
        ],
        startAdvanceMinutes: [
          { required: true, message: "上班提前分钟不能为空", trigger: "blur" },
        ],
        startDelayMinutes: [
          { required: true, message: "上班延迟分钟不能为空", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "下班时间不能为空", trigger: "blur" },
        ],
        endAdvanceMinutes: [
          { required: true, message: "下班提前分钟不能为空", trigger: "blur" },
        ],
        endDelayMinutes: [
          { required: true, message: "下班延迟分钟不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      options: [
        {
          value: "1",
          label: "周一"
        },
        {
          value: "2",
          label: "周二"
        },
        {
          value: "3",
          label: "周三"
        },
        {
          value: "4",
          label: "周四"
        },
        {
          value: "5",
          label: "周五"
        },
        {
          value: "6",
          label: "周六"
        },
        {
          value: "7",
          label: "星期天"
        }
      ],
      companyResult: [],
      companyProps: {
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  created() {
    var self = this;

    companyInfoApi.list({scope:"all"}).then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      
      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;
            self.submitting = true;

            var requestModel = JSON.parse(JSON.stringify(self.formModel));

            if (requestModel.weekdays != null) {
                var weekdays = requestModel.weekdays.join(",");
                requestModel.weekdays = weekdays;
            }

            if (id == null || id.length == 0) {
              return workScheduleApi.add(requestModel);
            } else {
              return workScheduleApi.update(requestModel);
            }
          })().then(function (response) {
            self.submitting = false;

            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return workScheduleApi.create();
      } else {
        return workScheduleApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          var weekdays = self.formModel.weekdays;
          if (weekdays != null) {
            self.formModel.weekdays = weekdays.split(",");
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  },
};
</script>