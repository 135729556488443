<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel"
    v-loading="loading"
    :element-loading-text="loadingText">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'120px'"
      >
        <el-form-item label="更新班次">
          <el-input :value="scheduleName" readonly></el-input>
        </el-form-item>
        <el-form-item label="考勤时段" prop="companyId">
          <el-date-picker
            v-model="formModel.timeRanges"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="loading">更新</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import workScheduleApi from "@/api/base/workSchedule";
import companyInfoApi from "@/api/base/companyInfo";
import workScheduleReportApi from "@/api/business/workScheduleReport";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["multipleSelection", "title"],
  computed: {
    scheduleName() {
      return this.multipleSelection
        .map((record) => {
          return record.name;
        })
        .join(",");
    },
  },
  data() {
    return {
      formModel: {
        timeRanges: null,
      },
      ruleValidate: {
        timeRanges: [
          { required: true, message: "考勤时段不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      loadingText: ""
    };
  },
  created() {
    var self = this;
  },
  methods: {
    //初始化日期
    getCurrentMonthFirst() {
      var self = this;
      var date = new Date();
      date.setDate(1);
      var month = parseInt(date.getMonth() + 1);
      var startTime = date.getFullYear() + "-" + month + "-" + date.getDate();

      date.setMonth(date.getMonth() + 1);
      var lastDate = new Date(date.getTime() - 1000 * 60 * 60 * 24);

      var endTime =
        lastDate.getFullYear() + "-" + month + "-" + lastDate.getDate();

      self.formModel.timeRanges = [startTime, endTime];
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          // self
          //   .$confirm("是否确认更新考勤数据?", "提示", {
          //     confirmButtonText: "确定",
          //     cancelButtonText: "取消",
          //     type: "warning",
          //   })
          //   .then(() => {
              var formData = new FormData();

              var workScheduleIds = this.multipleSelection
                .map((record) => {
                  return record.id;
                })
                .join(",");

              formData.append("workScheduleIds", workScheduleIds);
              formData.append("startDate", this.formModel.timeRanges[0]);
              formData.append("endDate", this.formModel.timeRanges[1]);

              self.loading = true;
              self.loadingText = "考勤数据生成中...";

              workScheduleReportApi
                .batchUpdate(formData)
                .then(function (response) {
                  var jsonData = response.data;

                  self.loading = false;

                  if (jsonData.result) {
                    self.$message({
                      type: "success",
                      message: jsonData.data + "",
                    });

                    self.closeDialog();
                  } else {
                    self.$message({
                      type: "warning",
                      message: jsonData.message + "",
                    });
                  }
                });
            // });
        }
      });
    },
  },
  mounted: function () {
    var self = this;
    this.getCurrentMonthFirst();
  },
};
</script>