
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'120px'"
      >
        <el-form-item label="考勤部门" prop="clockIn">
          <el-tree
           v-model="formModel.clockIn"
            :data="companyResultData"
            show-checkbox
            node-key="id"
            ref="tree"
            highlight-current
            :props="companyProps">
          </el-tree>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import workScheduleApi from "@/api/base/workSchedule";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        name: [
          { required: true, message: "班次名称不能为空", trigger: "blur" },
        ],
        weekdays: [
          { required: true, message: "周工作日不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "上班时间不能为空", trigger: "blur" },
        ],
        startAdvanceMinutes: [
          { required: true, message: "上班提前分钟不能为空", trigger: "blur" },
        ],
        startDelayMinutes: [
          { required: true, message: "上班延迟分钟不能为空", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "下班时间不能为空", trigger: "blur" },
        ],
        endAdvanceMinutes: [
          { required: true, message: "下班提前分钟不能为空", trigger: "blur" },
        ],
        endDelayMinutes: [
          { required: true, message: "下班延迟分钟不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      options: [
        {
          value: "1",
          label: "周一"
        },
        {
          value: "2",
          label: "周二"
        },
        {
          value: "3",
          label: "周三"
        },
        {
          value: "4",
          label: "周四"
        },
        {
          value: "5",
          label: "周五"
        },
        {
          value: "6",
          label: "周六"
        },
        {
          value: "7",
          label: "星期天"
        }
      ],
      companyResult: [],
      companyProps: {
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  computed: {
    /* 转树形数据 */
    companyResultData() {
      //第一个节点为根节点
      if (this.companyResult.length > 0) {
        var rootId = this.companyResult[0].id;

        let cloneData = JSON.parse(JSON.stringify(this.companyResult)); // 对源数据深度克隆
        return cloneData.filter(father => {
          // 循环所有项，并添加children属性
          let branchArr = cloneData.filter(
            child => father.id == child.parentId
          ); // 返回每一项的子级数组
          branchArr.length > 0 ? (father.children = branchArr) : ""; //给父级添加一个children属性，并赋值
          return father.id == rootId || father.parentId == null; //返回第一层
        });
      } else {
        return [];
      }
    }
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      self.formModel.clockIn = this.$refs.tree.getCheckedKeys();
      
      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (self.formModel.weekdays != null) {
                var weekdays = self.formModel.weekdays.join(",");
                self.formModel.weekdays = weekdays;
            }

            
            if (self.formModel.clockIn != null) {
                var clockIn = self.formModel.clockIn.join(",");
                self.formModel.clockIn = clockIn;
            }

            return workScheduleApi.updateCompany(self.formModel);
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return workScheduleApi.create();
      } else {
        return workScheduleApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          var weekdays = self.formModel.weekdays;
          if (weekdays != null) {
            self.formModel.weekdays = weekdays.split(",");
          }

          var clockIn = self.formModel.clockIn;
          if (clockIn != null) {
            this.$refs.tree.setCheckedKeys(clockIn.split(","));
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>